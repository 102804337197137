// main.js
import { createApp } from 'vue'
import App from './App.vue'
// 导入路由模块，其实 index.js 也可以省略
import router from '../router/index.js'
// 导入异步加载模块
import axios from 'axios'
// 将后端地址预设为本地(开发环境)，实际操作时先进行一次判断，如果出现链接问题则将后端地址改为生产环境
axios.defaults.baseURL = 'https://www.eyesunrose.com.cn'
axios.justle = 'production'
axios.post('http://127.0.0.1:7018/registing/debug').then(() => {
    axios.defaults.baseURL = 'http://127.0.0.1:7018'
    console.log('switch to development environment')
    axios.justle = 'development'
})

// 制作app，并且挂载所有icon
const app = createApp(App)
app.use(router)
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
app.mount('#app')
// 防抖，专门用于el-table的ResizeObserver loop limit exceeded
const debounce = (fn, delay) => {
    let timer = null;
    return function () {
        let context = this
        let args = arguments
        clearTimeout(timer)
        timer = setTimeout(function () {
            fn.apply(context, args)
        }, delay)
    }
}
const _ResizeObserver = window.ResizeObserver
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
    constructor(callback) {
        callback = debounce(callback, 16);
        super(callback)
    }
}